import { Link as RouterLink, useParams } from 'react-router-dom';
import React,{  } from 'react';
// apollo client
import { useQuery } from '@apollo/client';
// Circular Progress
import CircularProgress from '@mui/material/CircularProgress';
// @mui
import { Container, Box, Button, Skeleton } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// graphQL
import { BROCHURE_DETAILS, GET_TM_REPORT } from '../../graphQL/queries';


// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

// sections
import TMReport from '../../sections/@dashboard/TMReport/details';



// ----------------------------------------------------------------------

export default function TMReportDetails() {
  const { themeStretch } = useSettings();
  const { id } = useParams();

  const { loading, error, data, } = useQuery(GET_TM_REPORT, {
    variables: { id: id },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) return `Error! ${error.message}`;
  return (
    <Page title="Translation Memory View"> 
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Translation Memory - View`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Translation Memory List',
              href: PATH_DASHBOARD.TMReport.list,
            },
            {
              name: 'Translation Memory',
            },
          ]}
          action={
            <>
              <Button
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.TMReport.edit(id)}
                startIcon={<Iconify icon={'eva:edit-outline'} />}
              >
                Edit
              </Button>

            </>
            }
        />
        {loading && <Skeleton variant="rectangular" height={600} />}
        {!loading && !error && <TMReport tmReport={data?.TMReport}  />}
      </Container>
    </Page>
  );
}

import { Avatar, Box, Container, Typography } from '@mui/material';
import React, {useState, useEffect} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
  import { withScriptjs, withGoogleMap, GoogleMap, Marker, StreetViewPanorama } from "react-google-maps"
import styles from '../styles/AboutUs2.module.css';
import styles1 from '../styles/location.module.css'
import { useSelector } from 'react-redux';

const MyMapComponent = withScriptjs(withGoogleMap((props) => {
    console.log(props)
      return (
      <>
      <GoogleMap
        defaultZoom={14}
        defaultCenter={{ ...props.value }}
      >
        {props.isMarkerShown && <Marker position={{ ...props.value }} />}
        <StreetViewPanorama visible
      defaultPosition={{ ...props.value }}
    />
      </GoogleMap>
     
      </>
    )
      }))
function Location() {

  const { language, addOns, details:{address1:address}} = useSelector(state => state.proposal)

    const [center, setCenter] = useState();
    const [zoom, setZoom] = useState(13);
      useEffect(()=>{
        async function fetchLocation(){
          if(address){
            const results = await geocodeByAddress(address);
              const latLng = await getLatLng(results[0]);
              setCenter({...latLng})

             }
        }
       fetchLocation()
      },[address])
  const theme = useTheme();
  return (
 
      <Box
      className={styles1.location_page}
        sx={{
          display: 'flex',
          marginTop: '2px',
          height:`${center ? "auto" : "900px"}`,
          position:"relative",
          marginBottom:"40px"
        }}
      >
      <div className={styles.desktop8Item} />
      {/* <div className={styles.div}>09</div> */}
      <div className={styles.aboutUs}>
      {language === "English" ? `YOUR EVENT LOCATION`
: `LA LOCATION DEL VOSTRO EVENTO` 
}
        </div>
      <div className={styles.text3543Parent}>
      <img
        alt=""
        src="/assets/desktop6/group-1000001719.svg"
      /> </div>
        <div style={{ padding: '0px', marginLeft: '0px', width: '-webkit-fill-available', marginTop:"70px" }}>
        {center &&  <MyMapComponent
  value={center}
  isMarkerShown
  googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCNIhNs48EpiwN5C5sptZF2zvLqvwBpQR8"
  loadingElement={<div style={{ height: `100%` }} />}
  containerElement={<div className={styles1.location} style={{ height: `700px`, margin:"20px", marginBottom:"50px", border:"14px solid #39065F", marginInline:"auto" }} />}
  mapElement={<div style={{ height: `100%` }} />}
/>
}

{addOns.noOfEvents && <div className={styles.events}>
  {language === "English" ? `NUMBER OF EVENTS HELD AT THIS LOCATION : ${Math.round(Math.random() * 46) + 4}`
: `NUMERO DI EVENTI SVOLTI IN QUESTA LOCATION : ${Math.round(Math.random() * 46) + 4}` 
}
  
   </div>
  }

        </div>
      </Box>
  );
}

export default Location;

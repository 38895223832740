// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// hooks
import useSettings from 'src/hooks/useSettings';
// components
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import TMReportCreateForm from 'src/sections/@dashboard/TMReport/TMReportCreateForm';

// ----------------------------------------------------------------------

export default function TMReportCreate() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Translation Memory: Add a new Translation Memory ">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={'Add a new Translation Memory '}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Translation Memory List',
              href: PATH_DASHBOARD.TMReport.list,
            },
            { name: 'New Translation Memory ' },
          ]}
        />

        <TMReportCreateForm />
      </Container>
    </Page>
  );
}

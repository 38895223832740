// @mui
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
// utils

// components
// Dialog Popup
import { fDateTime } from 'src/utils/formatTime';
import { fPercent } from 'src/utils/formatNumber';
import { sum } from 'lodash';



// ----------------------------------------------------------------------



export default function TMDetails({ tmReport, brochureId }) {

  if (!tmReport) {
    return null;
  }

  const { createdAt, client, project, files, tmNumber, projectManager } = tmReport;


  return (
    <>
      <Card sx={{ paddingTop: "10px" }}>
        <Grid container rowSpacing={3} sx={{ padding: "25px", borderBottom: "1px solid lightGrey" }}>
          <Grid item xs={6} sm={4} sx={{ mb: 2 }}>
            <Typography variant="h3">
              No. {tmNumber}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4} sx={{ mb: 2 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Client Name
            </Typography>
            <Typography variant="body2">{client.name}</Typography>
          </Grid>
          <Grid item xs={6} sm={4} sx={{ mb: 2 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Creation Date
            </Typography>
            <Typography variant="body2">{fDateTime(createdAt)}</Typography>
          </Grid>  <Grid item xs={6} sm={4} sx={{ mb: 2 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Project Manager
            </Typography>
            <Typography variant="body2">{projectManager?.name}</Typography>
          </Grid>
          <Grid item xs={6} sm={4} sx={{ mb: 2 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Order No
            </Typography>
            <Typography variant="body2">
              {project?.type}/{project?.orderNumber}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ textAlignLast: 'center', paddingTop: '30px' }}>
              TRANSLATION MEMORY INFORMATION
            </Typography>
            <Typography variant='h6' style={{ paddingLeft: 20 }}>Project TM Report </Typography>
            <TableContainer sx={{ mt: 2, mb: 2 }}>
              <Table size='small'>
                <TableHead
                  sx={{
                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    '& th': { backgroundColor: 'transparent' },
                  }}
                >
                  <TableRow
                    sx={{
                      '& .css-oa1hrg-MuiTableCell-root:first-of-type': { boxShadow: 'none' },
                      '& .css-oa1hrg-MuiTableCell-root:last-of-type': { boxShadow: 'none', borderRadius: 'none' },
                    }}
                  >

                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Words</TableCell>
                    <TableCell align="left">Percent</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    [
                      "exactMatches",
                      "fuzzyMatches50to74",
                      "fuzzyMatches75to84",
                      "fuzzyMatches85to94",
                      "fuzzyMatches94to99",
                      "noMatch",
                    ].map((item, index) => {
                      function getDescriptionForKey(key) {
                        const descriptions = {
                          exactMatches: "Exact Matches",
                          fuzzyMatches50to74: "Fuzzy Matches 50-74%",
                          fuzzyMatches75to84: "Fuzzy Matches 75-84%",
                          fuzzyMatches85to94: "Fuzzy Matches 85-94%",
                          fuzzyMatches94to99: "Fuzzy Matches 94-99%",
                          noMatch: "No Match",
                        };

                        return descriptions[key] || "Unknown Key";
                      }
                      let rowSum = sum(files?.map(file => Number(file[item])))
                      let totalWords = sum(files?.map(file => Number(file['totalWords'])))
                      let Percent = rowSum / totalWords * 100
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                          }}
                        >
                          <TableCell align="left">{getDescriptionForKey(item)}</TableCell>
                          <TableCell align="left">{rowSum}</TableCell>
                          <TableCell align="left">{fPercent(Percent)}</TableCell>
                          {/* <TableCell align="left">{row?.targetLanguage?.join(', ')}</TableCell> */}
                          {/* <TableCell align="left">{row?.fileName}</TableCell> */}
                          {/* <TableCell align="left">{row?.totalSegments}</TableCell>
                            <TableCell align="left">{row?.analyzedSegments}</TableCell>
                            <TableCell align="left">{row?.exactMatches}</TableCell>
                            <TableCell align="left">{row?.fuzzyMatches50to74}</TableCell>
                            <TableCell align="left">{row?.fuzzyMatches75to84}</TableCell>
                            <TableCell align="left">{row?.fuzzyMatches85to94}</TableCell>
                            <TableCell align="left">{row?.fuzzyMatches94to99}</TableCell>
                            <TableCell align="left">{row?.noMatch}</TableCell> */}


                        </TableRow>
                      );
                    })}
                  <TableRow
                    sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      {"Total"}
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>

                      {
                        sum(files.map(file =>
                          Number(file['exactMatches']) +
                          Number(file['fuzzyMatches50to74']) +
                          Number(file['fuzzyMatches75to84']) +
                          Number(file['fuzzyMatches85to94']) +
                          Number(file['fuzzyMatches94to99']) +
                          Number(file['noMatch'])
                        ))
                      }
                    </TableCell>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>
                      {fPercent(
                        sum(files.map(file =>
                          Number(file['exactMatches']) +
                          Number(file['fuzzyMatches50to74']) +
                          Number(file['fuzzyMatches75to84']) +
                          Number(file['fuzzyMatches85to94']) +
                          Number(file['fuzzyMatches94to99']) +
                          Number(file['noMatch'])
                        )) / sum(files.map(file => Number(file['totalWords'])
                        )) * 100)
                      }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Typography variant='h6' style={{ paddingLeft: 20 }}>Files TM Report </Typography> */}
            {
              files?.map(file => {
                return (<>
                  <Typography variant='h6' style={{ paddingLeft: 20 }}>TM Report File Name: {file?.fileName} </Typography>
                  <TableContainer sx={{ mt: 2, mb: 2 }}>
                    <Table size='small'>
                      <TableHead
                        sx={{
                          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                          '& th': { backgroundColor: 'transparent' },
                        }}
                      >
                        <TableRow
                          sx={{
                            '& .css-oa1hrg-MuiTableCell-root:first-of-type': { boxShadow: 'none' },
                            '& .css-oa1hrg-MuiTableCell-root:last-of-type': { boxShadow: 'none', borderRadius: 'none' },
                          }}
                        >

                          <TableCell align="left">Type</TableCell>
                          <TableCell align="left">Words</TableCell>
                          <TableCell align="left">Percent</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          [
                            "exactMatches",
                            "fuzzyMatches50to74",
                            "fuzzyMatches75to84",
                            "fuzzyMatches85to94",
                            "fuzzyMatches94to99",
                            "noMatch",
                          ].map((item, index) => {
                            function getDescriptionForKey(key) {
                              const descriptions = {
                                exactMatches: "Exact Matches",
                                fuzzyMatches50to74: "Fuzzy Matches 50-74%",
                                fuzzyMatches75to84: "Fuzzy Matches 75-84%",
                                fuzzyMatches85to94: "Fuzzy Matches 85-94%",
                                fuzzyMatches94to99: "Fuzzy Matches 94-99%",
                                noMatch: "No Match",
                              };

                              return descriptions[key] || "Unknown Key";
                            }
                            let rowSum = Number(file[item])
                            let totalWords = Number(file['totalWords'])
                            let Percent = rowSum / totalWords * 100
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                }}
                              >
                                <TableCell align="left">{getDescriptionForKey(item)}</TableCell>
                                <TableCell align="left">{rowSum}</TableCell>
                                <TableCell align="left">{fPercent(Percent)}</TableCell>



                              </TableRow>
                            );
                          })}
                        <TableRow
                          sx={{
                            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                          }}
                        >
                          <TableCell align="left" style={{ fontWeight: "bold" }}>
                            {"Total"}
                          </TableCell>
                          <TableCell align="left" style={{ fontWeight: "bold" }}>

                            {
                              Number(file['exactMatches']) +
                              Number(file['fuzzyMatches50to74']) +
                              Number(file['fuzzyMatches75to84']) +
                              Number(file['fuzzyMatches85to94']) +
                              Number(file['fuzzyMatches94to99']) +
                              Number(file['noMatch'])
                            }
                          </TableCell>
                          <TableCell align="left" style={{ fontWeight: "bold" }}>
                            {fPercent(
                              (
                                Number(file['exactMatches']) +
                                Number(file['fuzzyMatches50to74']) +
                                Number(file['fuzzyMatches75to84']) +
                                Number(file['fuzzyMatches85to94']) +
                                Number(file['fuzzyMatches94to99']) +
                                Number(file['noMatch']))
                              / (Number(file['totalWords']))
                              * 100)
                            }
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>)
              })
            }


          </Grid>
        </Grid>
      </Card>

    </>
  );
}

import { Stack, InputAdornment, TextField } from '@mui/material';
// components
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------


export default function TMTableToolbar({
  clientName,
  filterClientName
}) {
console.log({clientName})
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent='flex-end'
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}
    >




      <TextField
        sx={{ width: 300 }}
        value={clientName}
        onChange={filterClientName}
        placeholder="Search By Client..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />


    </Stack>
  );
}

import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
// form
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Grid,
  Stack,
  Autocomplete,
  CircularProgress,
  TextField,
  Skeleton,
  Button,
  IconButton,
  Divider,
  Typography,
} from '@mui/material';
// graphQL
import { EDIT_TM_REPORT } from '../../../graphQL/mutations';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { GET_CLIENTS_LIST, GET_PROJECT_INFO, GET_PROJECT_LIST, MANAGERS_LIST } from 'src/graphQL/queries';
import Iconify from 'src/components/Iconify';
import { languages } from 'src/_mock';

// ----------------------------------------------------------------------

export default function TMReportCreateForm({ currentTMReport ,isEdit}) {
  const navigate = useNavigate();
  const [isEditForm , setIsEditForm]  =useState(isEdit)
  const { id } = useParams()
  const [openClients, setOpenClients] = useState(false)
  const [clientList, setClientList] = useState([currentTMReport?.client])
  const [openProjects, setOpenProjects] = useState(false)
  const [projectsList, setProjectsList] = useState([currentTMReport?.project]);
  const [openUser, setOpenUser] = useState(false)
  const [users, setUsers] = useState([currentTMReport?.projectManager])
  const [fetchClients, { loading: clientsLoading, }] = useLazyQuery(GET_CLIENTS_LIST);
  const [fetchProjects, { loading: projectsLoading, }] = useLazyQuery(GET_PROJECT_LIST);
  const [fetchProjectInfo, { loading: projectInfoLoading, error }] = useLazyQuery(GET_PROJECT_INFO);
  const [fetch, {  loading: userLoading,  }] = useLazyQuery(MANAGERS_LIST);

  // let projectInfoLoading = true
  const [edit] = useMutation(EDIT_TM_REPORT);
  const { enqueueSnackbar } = useSnackbar();
  const NewProductSchema = Yup.object().shape({
    client: Yup.object().required("Client required").nullable(),
    project: Yup.object().shape({
      id: Yup.string(),
      orderNumber: Yup.number(),
      type: Yup.string()
    }).required("Project required").nullable(),
    files: Yup.array().required('Files Required').nullable(),
    projectManager: Yup.object().required("Project Manager required").nullable()
  });

  const defaultValues = useMemo(() => ({
    client: currentTMReport?.client || '',
    project: currentTMReport?.project || '',
    files: currentTMReport?.files || [],
    projectManager: currentTMReport?.projectManager || ''

  }));

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;
  // useEffect(() => {
  //   function setValuesToForm() {
  //     console.log('See', currentTMReport);
  //     if (currentTMReport) {
  //       // setAddOns({...currentTranslation.addOns})
  //       setValue('files', currentTMReport?.files);
  //       // setValue();ss
  //       // reset(defaultValues);
  //     }
  //   }

  //   setValuesToForm()
  // }, [currentTMReport]);
  console.log('errors', errors);
  const { append, remove } = useFieldArray({
    control,
    name: 'files',
  });
  const handleDelete = (index) => {
    remove(index);
  }
  const handleDuplicate = (index) => {
    append({ ...values.files[index] })

  }
  const handleAddNew = () => {
    append({
      'fileName': '',
      'totalWords': 0,
      "exactMatches": 0,
      "fuzzyMatches50to74": 0,
      "fuzzyMatches75to84": 0,
      "fuzzyMatches85to94": 0,
      "fuzzyMatches94to99": 0,
      "noMatch": 0,
      sourceLanguage: '',
      targetLanguage: []
    })
  }
  const values = watch();
  console.log(values);


  const onSubmit = async (data) => {
    console.log('item', data);
    try {
      console.log(values);
      await edit({
        variables: {
          id: id,
          tmReportDetails: {
            ...data,
          },
        },
      })

        .then((res) => {
          enqueueSnackbar('Create success!');
          navigate(PATH_DASHBOARD.TMReport.view(id));
        })
        .catch((calendarError) => console.log(calendarError));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function getProjectInfo() {
      console.log({isEditForm})
      if (values?.project && !isEditForm) {
        await fetchProjectInfo({
          variables: {
            "id": values?.project?.id,
            "type": values?.project?.type
          }
        })
          .then((res) => {
            setClientList(res.data.getFilterdProjectInfo);
            let data = res.data.getFilterdProjectInfo?.map(item => ({
              'fileName': item?.fileName,
              'totalWords': 0,
              "exactMatches": 0,
              "fuzzyMatches50to74": 0,
              "fuzzyMatches75to84": 0,
              "fuzzyMatches85to94": 0,
              "fuzzyMatches94to99": 0,
              "noMatch": 0,
              sourceLanguage: item?.sourceLanguage?.name,
              targetLanguage: item?.targetLanguage?.map(item => item?.name)
            }))
            setValue("files", data)

          })
          .catch((err) => {
            setValue('files', []);
          });
      }
    }
    getProjectInfo()
  }, [values?.project]);
  useEffect(() => {
    async function fetchPM() {
      if (openUser) {
        console.debug(`fetching...?`)
        await fetch({ variables: { department: "Project Management" } }, {
          fetchPolicy: 'no-cache'
        }).then((res) => setUsers([...res.data.managersList].map(item => ({ name: item?.name, email: item?.email }))))
          .catch((err) => console.log(err))
      }
    }
    fetchPM()
  }, [openUser])
  useEffect(() => {
    async function getClients() {
      if (openClients) {
        await fetchClients()
          .then((res) => {
            if (!res.data.getClientsList.length) {
              setClientList([]);
            }
            setClientList(res.data.getClientsList);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setIsEditForm(false)
          })
          .catch((err) => {
            setClientList([]);
          });
      }
    }
    getClients()
  }, [fetchClients, openClients]);
  useEffect(() => {
    async function getClients() {
      if (openProjects) {
        await fetchProjects(
          { variables: { 'clientId': values?.client?.id, 'year': '2024' } }
        )
          .then((res) => {
            if (!res.data.getFilterdProjectList.length) {
              setProjectsList([]);
            }
            setProjectsList(res.data.getFilterdProjectList);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setIsEditForm(false)
          })
          .catch((err) => {
            setProjectsList([]);
          });
      }
    }
    getClients()
  }, [fetchProjects, openProjects, values?.client?.id]);
console.log({values})
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>

        <Grid item xs={12} md={4}>
          <Controller
            name="client"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const errMsg = error && 'Clients is required';
              return (
                <Autocomplete
                  fullWidth
                  open={openClients}
                  onChange={(event, newValue) => field.onChange(newValue)}
                  onOpen={() => {
                    setOpenClients(true);
                  }}
                  onClose={() => {
                    setOpenClients(false);
                  }}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  getOptionLabel={(option) => option.name}
                  value = {values?.client || ''}
                  options={clientList}
                  loading={clientsLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={errMsg}
                      label="Client Name"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {clientsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="project"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const errMsg = error && 'Project required';
              return (
                <Autocomplete
                  fullWidth
                  open={openProjects}
                  onChange={(event, newValue) => field.onChange(newValue)}
                  onOpen={() => {
                    setOpenProjects(true);
                  }}
                  onClose={() => {
                    setOpenProjects(false);
                  }}
                  isOptionEqualToValue={(option, value) => option.orderNumber === value.orderNumber}
                  getOptionLabel={(option) => `${option.type}/${option?.orderNumber}`}
                  options={projectsList}
                  value = {values?.project || ''}

                  loading={projectsLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={errMsg}
                      label="Project No"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {projectsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              );
            }}
          />
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Controller
            name="projectManager"
            control={control}
            render={({ field, fieldState: { error } }) => {
              const errMsg = error && 'Project Manager is required';
              return (
                <Autocomplete
                  fullWidth
                  open={openUser}
                  value={values?.projectManager}
                  onChange={(event, newValue) => field.onChange(newValue)}
                  onOpen={() => {
                    setOpenUser(true);
                  }}
                  onClose={() => {
                    setOpenUser(false);
                  }}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  getOptionLabel={(option) => option.name}
                  options={users}
                  loading={userLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={errMsg}
                      label="Project Manager"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              );
            }} />
        </Grid>
    

        {
          projectInfoLoading &&
          <Grid item xs={12} style={{
            marginTop: -60,
            marginBottom: -60
          }}>
            <Skeleton height={320} width='100%' />
          </Grid>
        }
        <Grid item xs={12} >
          <Divider variant="h6">
            <Typography variant="h6" color="default">
              File Information
            </Typography>
          </Divider>
          <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3} mt={3}>

            {!error && !projectInfoLoading && values?.files && values?.files?.length > 0 &&
              values?.files?.map((item, index) =>
                <Stack key={index} alignItems="flex-end" spacing={1.5} py={2}>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>


                    <Controller
                      name={`files[${index}].sourceLanguage`}
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        const errMsg = error && 'Source Language required';
                        return (
                          <Autocomplete
                            sx={{
                              '& .MuiAutocomplete-input': { marginTop: '-10px' },
                              width: '-webkit-fill-available',
                              height: '40px',
                              '& .MuiOutlinedInput-root': { height: '37px' },
                            }}
                            disablePortal
                            value={values.files[index].sourceLanguage}
                            name={`files[${index}].sourceLanguage`}
                            onChange={(event, newValue) => setValue(`files[${index}].sourceLanguage`, newValue?.name)}
                            options={languages}
                            getOptionLabel={(option) => typeof option === 'string' ? option : option?.name}
                            isOptionEqualToValue={(option, value) => option?.name === value}

                            renderInput={(params) => (
                              <TextField
                                sx={{
                                  '& .css-1p408oi-MuiFormLabel-root-MuiInputLabel-root': {
                                    top: '-8px',
                                  },
                                }}
                                {...params}
                                error={!!error}
                                helperText={errMsg}
                                label="Source Language"
                              />
                            )}
                          />
                        );
                      }}
                    />
                    <Controller
                      name={`files[${index}].targetLanguage`}
                      control={control}
                      render={({ field, fieldState: { error } }) => {
                        const errMsg = error && 'Target Language is required';
                        return (
                          <Autocomplete
                            multiple
                            sx={{
                              '& .MuiAutocomplete-tag': { marginTop: '-5px' },
                              width: '-webkit-fill-available',
                              '& .MuiOutlinedInput-root': { height: 'auto' },
                            }}

                            onChange={(event, newValue) => setValue(`files[${index}].targetLanguage`, newValue)}
                            options={languages?.map(item => item?.name)}
                            value={values.files[index].targetLanguage || []}
                            name={`files[${index}].targetLanguage`}
                            filterSelectedOptions
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{
                                  '& .css-1p408oi-MuiFormLabel-root-MuiInputLabel-root': {
                                    top: '-8px',
                                  },
                                }}
                                error={!!error}
                                helperText={errMsg}
                                label="Target Language"
                              />
                            )}
                          />
                        );
                      }}
                    />

                    <RHFTextField
                      size="small"
                      name={`files[${index}].fileName`}
                      label="File Name"
                      InputLabelProps={{ shrink: true }}
                      value={item?.fileName}
                      fullWidth
                    />
                  </Stack>

                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                 
                   
                    <RHFTextField
                      name={`files[${index}].exactMatches`}
                      label="Exact Matches"
                      type="number"
                      size="small"
                      fullWidth
                      value={item?.exactMatches}
                      onChange={(event)=>{
                        setValue(`files[${index}].exactMatches`,event.target.value)
                       setValue(`files[${index}].totalWords` ,(
                       Number(item?.exactMatches) +
                       Number(item?.fuzzyMatches50to74) +
                       Number(item?.fuzzyMatches75to84) +
                       Number(item?.fuzzyMatches85to94) +
                       Number(item?.fuzzyMatches94to99) +
                       Number(item?.noMatch)))
                     
                     }}
                    />
                    <RHFTextField
                      size="small"
                      name={`files[${index}].fuzzyMatches50to74`}
                      label="Fuzzy Matches 50 to 74%"
                      fullWidth
                      value={item?.fuzzyMatches50to74}
                      onChange={(event)=>{
                        setValue(`files[${index}].fuzzyMatches50to74`,event.target.value)
                       setValue(`files[${index}].totalWords` ,(
                       Number(item?.exactMatches) +
                       Number(item?.fuzzyMatches50to74) +
                       Number(item?.fuzzyMatches75to84) +
                       Number(item?.fuzzyMatches85to94) +
                       Number(item?.fuzzyMatches94to99) +
                       Number(item?.noMatch)))
                     
                     }}
                    />
 <RHFTextField
                      size="small"
                      name={`files[${index}].fuzzyMatches75to84`}
                      label="Fuzzy Matches 75 to 84%"
                      fullWidth
                      value={item?.fuzzyMatches75to84}
                      onChange={(event)=>{
                        setValue(`files[${index}].fuzzyMatches75to84`,event.target.value)
                       setValue(`files[${index}].totalWords` ,(
                       Number(item?.exactMatches) +
                       Number(item?.fuzzyMatches50to74) +
                       Number(item?.fuzzyMatches75to84) +
                       Number(item?.fuzzyMatches85to94) +
                       Number(item?.fuzzyMatches94to99) +
                       Number(item?.noMatch)))
                     
                     }}
                    />  
                         <RHFTextField
                      size="small"
                      name={`files[${index}].fuzzyMatches85to94`}
                      label="Fuzzy Matches 85 to 94%"
                      fullWidth
                      value={item?.fuzzyMatches85to94}
                      onChange={(event)=>{
                        setValue(`files[${index}].fuzzyMatches85to94`,event.target.value)
                       setValue(`files[${index}].totalWords` ,(
                       Number(item?.exactMatches) +
                       Number(item?.fuzzyMatches50to74) +
                       Number(item?.fuzzyMatches75to84) +
                       Number(item?.fuzzyMatches85to94) +
                       Number(item?.fuzzyMatches94to99) +
                       Number(item?.noMatch)))
                     
                     }}
                    />
                  </Stack>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                   
               
                    <RHFTextField
                      size="small"
                      name={`files[${index}].fuzzyMatches94to99`}
                      label="Fuzzy Matches 94 to 99%"
                      fullWidth
                      value={item?.fuzzyMatches94to99}
                      onChange={(event)=>{
                        setValue(`files[${index}].fuzzyMatches94to99`,event.target.value)
                       setValue(`files[${index}].totalWords` ,(
                       Number(item?.exactMatches) +
                       Number(item?.fuzzyMatches50to74) +
                       Number(item?.fuzzyMatches75to84) +
                       Number(item?.fuzzyMatches85to94) +
                       Number(item?.fuzzyMatches94to99) +
                       Number(item?.noMatch)))
                     
                     }}
                    />
                    <RHFTextField
                      size="small"
                      name={`files[${index}].noMatch`}
                      label="No Match"
                      fullWidth
                      value={item?.noMatch}
                      onChange={(event)=>{
                        setValue(`files[${index}].noMatch`,event.target.value)
                       setValue(`files[${index}].totalWords` ,(
                       Number(item?.exactMatches) +
                       Number(item?.fuzzyMatches50to74) +
                       Number(item?.fuzzyMatches75to84) +
                       Number(item?.fuzzyMatches85to94) +
                       Number(item?.fuzzyMatches94to99) +
                       Number(item?.noMatch)))
                     
                     }}
                    />
                       <RHFTextField
                      size="small"
                      name={`files[${index}].totalWords`}
                      label="Total Words"
                      type="number"
                      value={item?.totalWords}
                      fullWidth
                      disabled
                      
                    />
                    <IconButton onClick={() => handleDelete(index)}>
                      <Iconify icon="eva:trash-2-outline" style={{ color: "red" }} />
                    </IconButton>
                    <IconButton onClick={() => handleDuplicate(index)}>
                      <Iconify icon="material-symbols:content-copy" style={{ color: "green" }} />
                    </IconButton>
                  </Stack>
                </Stack>

              )}
          </Stack>
          <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

          {!projectInfoLoading && values?.files && values?.files?.length > 0 &&
            <Stack
              spacing={2}
              direction={{ xs: 'column-reverse', md: 'row' }}
              alignItems={{ xs: 'flex-start', md: 'center' }}
            >
              <Button
                size="small"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={handleAddNew}
                sx={{ flexShrink: 0, border: `${errors.barzanoItem && ' 1px solid red'}`, marginBottom: '10px' }}
              >
                Add new File
              </Button>

            </Stack>}
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <RHFTextField type='number' name="commonMatch" label="Common Match" />
        </Grid>
        <Grid item xs={12} md={6}>
          <RHFTextField type='number' name="fuzzyMatch" label="Fuzzy Match" />
        </Grid>
        <Grid item xs={12} md={6}>
          <RHFTextField type='number' name="fullMatch" label="Full Match" />
        </Grid>
        <Grid item xs={12} md={6}>
          <RHFTextField type='number' name="NoMatch" label="No Match" />
        </Grid> */}
        <Grid item xs={12} >    <Card sx={{ p: 3 }} md={8}>
          <Stack spacing={3}>
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              loading={isSubmitting}
            >
              {'Edit Translation Memory '}
            </LoadingButton>
          </Stack>
        </Card>
        </Grid>

      </Grid>
    </FormProvider>
  );
}

import { useState } from 'react';
// @mui
import { TableRow, TableCell, MenuItem, DialogTitle, Link } from '@mui/material';
// utils
import { fDateTime } from '../../../../utils/formatTime';
// components
import Iconify from '../../../../components/Iconify';
import { TableMoreMenu } from '../../../../components/table';
import { DialogAnimate } from 'src/components/animate';
import DeleteConfirmation from '../DeleteConfirmation';

// ----------------------------------------------------------------------



export default function TMReportTableRow({
  row,
  onEditRow,
  refetch,
  onViewRow
}) {

  const {
    id,
    client,
    files,
    project,
    createdAt, tmNumber,
    projectManager
  } = row;
  const [openMenu, setOpenMenuActions] = useState(null);
  const [openDelete, setOpenDelete] = useState(false)

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };


  return (
    <TableRow hover >
      <TableCell align="left">
        <Link noWrap variant="subtitle2" onClick={onViewRow}>
          {tmNumber}
        </Link>
      </TableCell>

      <TableCell align="left">{`${project?.type}/${project?.orderNumber}`}</TableCell>
      <TableCell align="left">
        {client?.name}
      </TableCell>
      <TableCell align="left">{fDateTime(createdAt)}</TableCell>
      <TableCell align="left">{projectManager?.name}</TableCell>
      <TableCell align="left">{files?.map(item => item?.fileName).join(', ')}</TableCell>
      <TableCell align="right">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  onViewRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:eye-fill'} />
                View
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setOpenDelete(true);
                  handleCloseMenu();
                }}
              >
                <Iconify icon="eva:trash-fill" sx={{
                  color: "red"
                }} />
                Delete
              </MenuItem>
            </>
          }
        />
      </TableCell>
      <DialogAnimate sx={{ minWidth: 400 }} open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle>Delete Report</DialogTitle>
        <DeleteConfirmation handleCloseModal={() => setOpenDelete(false)} project={project} refetch={refetch} id={id} />
      </DialogAnimate>
    </TableRow >
  );
}

import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Button,
  TableBody,
  Container,
  Box,
  TablePagination,
  TableRow,
  TableCell,
  Skeleton,
} from '@mui/material';
// graphQL
import { GET_TM_REPORT_LIST, } from '../../graphQL/queries';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useTable from '../../hooks/useTable';
// _mock_
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableHeadCustom } from '../../components/table';
// sections
import { TMReportTableRow, TMTableToolbar, } from '../../sections/@dashboard/TMReport/list';


// ----------------------------------------------------------------------


const TABLE_HEAD = [
  { id: 'tmNumber', label: 'TM Number', align: 'left' },
  { id: 'project.orderNumber', label: 'Project No', align: 'left' }, 
   { id: 'Client', label: 'Client', align: 'left' },
  { id: 'createdAt', label: 'Creation Date', align: 'left' },
  { id: 'projectManager.name', label: 'Project Manager', align: 'left' },
  { id: 'fileName', label: 'File Name', align: 'left' },
  { id: '', label: 'Action', align: 'left' },
];

// ----------------------------------------------------------------------

export default function TMReportList() {
  const navigate = useNavigate()
  const [clientName , setClientName] = useState(null)
   const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('_id');
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  // graphQL connection
  const { loading, error, data, refetch } = useQuery(GET_TM_REPORT_LIST, {
    variables: { orderBy: orderBy, order: order, offset: page, limit: rowsPerPage , clientName:clientName },
    fetchPolicy: "no-cache"
  });
  const { dense } = useTable();

  const handleSort = async (id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }

  }
  const handleViewRow = (id) => {
    window.open(PATH_DASHBOARD.TMReport.view(id), '_blank');
  };
  const handleEditRow = (id) => {
    navigate(PATH_DASHBOARD.TMReport.edit(id), '_blank');
  };
const handleClientName = (event)=>{
  setClientName(event.target.value)
} 
  const handleChangePage = async (event, newPage) => {
    // setPage(newPage);
    setPage(newPage);
  }
  const handleChangeRowsPerPage = async (event) => {

    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  }
  if (error) return `Error! ${error.message}`;
  return (
    <Page title="Translation Memory : List">
      <Container maxWidth={'1000'}>
        <HeaderBreadcrumbs
          heading={'Translation Memory List'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'List' },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.TMReport.new}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              New Translation Memory
            </Button>
          }
        />


        <Card sx={{ overflow: "unset" }}>
        <TMTableToolbar
            clientName={clientName}
            filterClientName={handleClientName}

          />
          <Scrollbar>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={data?.allTMReports?.length || 0}
                onSort={handleSort}
                sx={{
                  '& .MuiTableCell-head': {
                    bgcolor: '#26a0fc',
                    color: '#ffff',
                  },
                }}
              />
              {(loading && !data) ?
                (
                  <TableBody>
                    {[1, 1, 1, 1, 1].map((item, i) => (
                      <TableRow >
                        {[1, 1, 1, 1, 1, 1, 1].map((item, i) => (
                          <TableCell key={i} align="center">
                            <Skeleton height='40px' width='100%' />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                )
                : (
                  <TableBody>
                    {data &&
                      data?.allTMReports
                        .map((row) => (
                          <TMReportTableRow
                            key={row.id}
                            row={row}
                            onViewRow={() => handleViewRow(row.id)}
                            onEditRow={() => handleEditRow(row.id)}
                            refetch={refetch}
                          />
                        ))}
                  </TableBody>
                )
              }
            </Table>
          </Scrollbar>


          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100, 500]}
              component="div"
              count={data?.allTMReports?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          </Box>
        </Card>
      </Container>
    </Page>
  );
}

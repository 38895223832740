export const languageMappings = {
  'Abkhaz': 'Abcasio',
  'Afar': 'Afar',
  'Afrikaans': 'Afrikaans',
  'Akan': 'Akan',
  'Albanian': 'Albanese',
  'Amharic': 'Amarico',
  'Arabic': 'Arabo',
  'Aragonese': 'Aragonese',
  'Armenian': 'Armeno',
  'Assamese': 'Assamese',
  'Avaric': 'Avaro',
  'Avestan': 'Avestico',
  'Aymara': 'Aymara',
  'Azerbaijani': 'Azero',
  'Bambara': 'Bambara',
  'Bashkir': 'Baschiro',
  'Basque': 'Basco',
  'Belarusian': 'Bielorusso',
  'Bengali': 'Bengalese',
  'Bihari': 'Bihari',
  'Bislama': 'Bislama',
  'Bosnian': 'Bosniaco',
  'Breton': 'Bretone',
  'Bulgarian': 'Bulgaro',
  'Burmese': 'Birmano',
  'Catalan; Valencian': 'Catalano',
  'Chamorro': 'Chamorro',
  'Chechen': 'Ceceno',
  'Chichewa; Chewa; Nyanja': 'Chichewa',
  'Chinese': 'Cinese',
  'Chuvash': 'Ciuvascio',
  'Cornish': 'Cornico',
  'Corsican': 'Corso',
  'Cree': 'Cree',
  'Croatian': 'Croato',
  'Czech': 'Ceco',
  'Danish': 'Danese',
  'Divehi; Dhivehi; Maldivian;': 'Maldiviano',
  'Dutch': 'Olandese',
  'English': 'Inglese',
  'Esperanto': 'Esperanto',
  'Estonian': 'Estone',
  'Ewe': 'Ewe',
  'Faroese': 'Faroese',
  'Fijian': 'Figiano',
  'Filipino': 'Filippino',
  'Finnish': 'Finlandese',
  'Flemish': 'Fiammingo',
  'French': 'Francese',
  'Fula': 'Fula',
  'Galician': 'Galiziano',
  'Georgian': 'Georgiano',
  'German': 'Tedesco',
  'Greek, Modern': 'Greco',
  'Guaraní': 'Guaranì',
  'Gujarati': 'Gujarati',
  'Haitian; Haitian Creole': 'Creolo Haitiano',
  'Hausa': 'Hausa',
  'Hebrew (modern)': 'Ebraico (moderno)',
  'Herero': 'Herero',
  'Hindi': 'Hindi',
  'Hiri Motu': 'Hiri Motu',
  'Hungarian': 'Ungherese',
  'Interlingua': 'Interlingua',
  'Indonesian': 'Indonesiano',
  'Interlingue': 'Interlingue',
  'Irish': 'Irlandese',
  'Igbo': 'Igbo',
  'Inupiaq': 'Inupiaq',
  'Ido': 'Ido',
  'Icelandic': 'Islandese',
  'Italian': 'Italiano',
  'Inuktitut': 'Inuktitut',
  'Japanese': 'Giapponese',
  'Javanese': 'Giavanese',
  'Kalaallisut, Greenlandic': 'Kalaallisut',
  'Kannada': 'Kannada',
  'Kanuri': 'Kanuri',
  'Kashmiri': 'Kashmiri',
  'Kazakh': 'Kazako',
  'Khmer': 'Khmer',
  'Kikuyu, Gikuyu': 'Kikuyu',
  'Kinyarwanda': 'Kinyarwanda',
  'Kirghiz, Kyrgyz': 'Kirghiso',
  'Komi': 'Komi',
  'Kongo': 'Kongo',
  'Korean': 'Coreano',
  'Kurdish': 'Curdo',
  'Kwanyama, Kuanyama': 'Kwanyama',
  'Latin': 'Latino',
  'LIS': 'Lingua dei Segni',
  'Luxembourgish, Letzeburgesch': 'Lussemburghese',
  'Luganda': 'Luganda',
  'Limburgish, Limburgan, Limburger': 'Limburgese',
  'Lingala': 'Lingala',
  'Lao': 'Lao',
  'Lithuanian': 'Lituano',
  'Luba-Katanga': 'Luba-Katanga',
  'Latvian': 'Lettone',
  'Manx': 'Manx',
  'Macedonian': 'Macedone',
  'Malagasy': 'Malgascio',
  'Malay': 'Malese',
  'Malayalam': 'Malayalam',
  'Maltese': 'Maltese',
  'Māori': 'Māori',
  'Marathi (Marāṭhī)': 'Marathi',
  'Marshallese': 'Marshallese',
  'Moldovan': 'Moldovan',
  'Mongolian': 'Mongolo',
  'Montenegrin': 'Montenegrino',
  'Nauru': 'Nauruano',
  'Navajo, Navaho': 'Navajo',
  'Norwegian Bokmål': 'Norvegese Bokmål',
  'North Ndebele': 'Ndebele del Nord',
  'Nepali': 'Nepalese',
  'Ndonga': 'Ndonga',
  'Norwegian Nynorsk': 'Norvegese Nynorsk',
  'Norwegian': 'Norvegese',
  'Nuosu': 'Nuosu',
  'South Ndebele': 'Ndebele del Sud',
  'Occitan': 'Occitano',
  'Ojibwe, Ojibwa': 'Ojibwa',
  'Oromo': 'Oromo',
  'Oriya': 'Oriya',
  'Ossetian, Ossetic': 'Osseto',
  'Panjabi, Punjabi': 'Punjabi',
  'Pāli': 'Pali',
  'Persian': 'Persiano',
  'Polish': 'Polacco',
  'Pashto, Pushto': 'Pashto',
  'Portuguese': 'Portoghese',
  'Quechua': 'Quechua',
  'Romansh': 'Romancio',
  'Kirundi': 'Kirundi',
  'Romanian': 'Rumeno',
  'Russian': 'Russo',
  'Sanskrit (Saṁskṛta)': 'Sanscrito',
  'Sardinian': 'Sardo',
  'Sindhi': 'Sindhi',
  'Northern Sami': 'Sami del Nord',
  'Samoan': 'Samoano',
  'Sango': 'Sango',
  'Serbian': 'Serbo',
  'Scottish Gaelic; Gaelic': 'Gaelico scozzese',
  'Shona': 'Shona',
  'Sinhala, Sinhalese': 'Singalese',
  'Slovak': 'Slovacco',
  'Slovene': 'Sloveno',
  'Somali': 'Somalo',
  'Southern Sotho': 'Sotho del Sud',
  'Spanish': 'Spagnolo',
  'Sundanese': 'Sundanese',
  'Swahili': 'Swahili',
  'Swati': 'Swati',
  'Swedish': 'Svedese',
  'Tamil': 'Tamil',
  'Telugu': 'Telugu',
  'Tajik': 'Tagico',
  'Thai': 'Thai',
  'Tigrinya': 'Tigrinya',
  'Tibetan Standard, Tibetan, Central': 'Tibetano',
  'Turkmen': 'Turkmeno',
  'Tagalog': 'Tagalog',
  'Tswana': 'Tswana',
  'Tonga (Tonga Islands)': 'Tonga',
  'Turkish': 'Turco',
  'Tsonga': 'Tsonga',
  'Tatar': 'Tataro',
  'Twi': 'Twi',
  'Tahitian': 'Tahitiano',
  'Uighur, Uyghur': 'Uiguro',
  'Ukrainian': 'Ucraino',
  'Urdu': 'Urdu',
  'Uzbek': 'Uzbeco',
  'Venda': 'Venda',
  'Vietnamese': 'Vietnamita',
  'Volapük': 'Volapük',
  'Walloon': 'Vallone',
  'Welsh': 'Gallese',
  'Wolof': 'Wolof',
  'Western Frisian': 'Frisone occidentale',
  'Xhosa': 'Xhosa',
  'Yiddish': 'Yiddish',
  'Yoruba': 'Yoruba',
  'Zhuang, Chuang': 'Zhuang'
};
  

import { gql } from '@apollo/client';

// ==============> TRANSLATION PROJECT <================

export const PROPOSAL_NOTIFICATION = gql`
mutation PageOpeningNotification($pageOpeningNotificationId: ID) {
  pageOpeningNotification(id: $pageOpeningNotificationId)
}
`
export const CREATE_TRANSLATION_PROJECT = gql`
  mutation Mutation($translationOrderDetails: translationOrderDetails) {
    addTranslationOrders(translationOrderDetails: $translationOrderDetails) {
      originalLink
      id
      orderNumber
    }
  }
`;
export const DUPLICATE_TRANSLATION_QUOTE = gql`
mutation Mutation($duplicateOrderId: ID) {
  duplicateOrder(id: $duplicateOrderId) {
    id
    status
    quoteNumber
  }
}
`
export const DELETE_PAYMENT_LINK_TRANSLATION = gql`
mutation Mutation($id: String) {
  deleteTranslationPaymentLink(id: $id)
}
`;
export const DELETE_PAYMENT_LINK_INTERPRETATION = gql`
mutation Mutation($id: String) {
  deletePaymentLink(id: $id)
}
`
export const EDIT_TRANSLATION_PROJECT = gql`
  mutation EditTranslationOrders($editTranslationOrdersId: String, $translationOrderDetails: translationOrderDetails) {
    editTranslationOrders(id: $editTranslationOrdersId, translationOrderDetails: $translationOrderDetails) {
      id
    }
  }
`;
export const GENERATE_TRANSLATION_PAYMENT_LINK = gql`
mutation GenerateTranslationPaymentLink($id:String, $amount:String){
  generateTranslationPaymentLink(id:$id, amount:$amount){
      url
      isPaid
      amount
  }
}
`
export const CREATE_TRANSLATION_QUOTE = gql`
  mutation Mutation($translationOrderDetails: translationOrderDetails,  $avatar:Upload) {
    addTranslationQuoteOrders(translationOrderDetails: $translationOrderDetails, avatar:$avatar) {
      originalLink
      id
      orderNumber
    }
  }
`;
export const PROJECT_INITIATE_TRANSLATION = gql`
mutation InitiateTranslationProject($initiateProjectId: ID ,$files: [Upload], $optionSelected:Int) {
  initiateTranslationProject(id: $initiateProjectId , files: $files, optionSelected:$optionSelected)
}
`
export const EDIT_TRANSLATION_QUOTE = gql`
  mutation EditTranslationOrders($editTranslationOrdersId: String, $translationOrderDetails: translationOrderDetails, $avatar:Upload) {
    editTranslationOrders(id: $editTranslationOrdersId, translationOrderDetails: $translationOrderDetails, avatar:$avatar) {
      id
      orderNumber
      followUpDate
      pmNotes
    }
  }
`;
export const QUOTE_ACCEPT_TRANSLATION = gql`
mutation QuoteTranslationAccept($selectedPackageDetails: selectedPackageDetails, $quoteAcceptId: ID, $name: String, $ip:String, $optionSelected:Int) {
  quoteTranslationAccept(selectedPackageDetails: $selectedPackageDetails, id: $quoteAcceptId, name: $name, ip:$ip, optionSelected:$optionSelected)
}
`

// ==============> TASK <================

export const CREATE_TASK = gql`
  mutation Mutation($taskDetails: taskDetails) {
    addTasks(taskDetails: $taskDetails) {
      id
      name
      summary
      dueDate
    }
  }
`;

export const EDIT_TASK = gql`
  mutation Mutation($editTaskId: String, $taskDetails: taskDetails) {
    editTasks(id: $editTaskId, taskDetails: $taskDetails) {
      id
  }
  }
`;

// ==============> BARZANO PROJECT <================



export const ADD_LINGUIST_SUBTOTAL_IN = gql`
mutation Mutation($projectId: ID, $linguistSubtotal: String) {
  addLinguistSubtotalIN(projectId: $projectId, linguistSubtotal: $linguistSubtotal)
}
`;

export const ADD_LINGUIST_SUBTOTAL_TR = gql`
mutation Mutation($projectId: ID, $linguistSubtotal: String) {
  addLinguistSubtotalTR(projectId: $projectId, linguistSubtotal: $linguistSubtotal)
}
`;

export const CREATE_BARZANO_PROJECT = gql`
  mutation Mutation($barzanoOrderDetails: barzanoOrderDetails) {
    addBarzanoOrders(barzanoOrderDetails: $barzanoOrderDetails) {
      originalLink
      id
      deliveryDateTime
      orderNumber
    }
  }
`;

export const EDIT_BARZANO_PROJECT = gql`
  mutation EditBarzanoOrders($editBarzanoOrdersId: String, $barzanoOrderDetails: barzanoOrderDetails) {
    editBarzanoOrders(id: $editBarzanoOrdersId, barzanoOrderDetails: $barzanoOrderDetails) {
      id
      orderNumber
     
    }
  }
`;


// =============> BROCHURE <===================

export const CREATE_BROCHURE = gql`
  mutation AddBrochure($brochureDetails: brochureDetails, $avatar:Upload) {
    addBrochure(brochureDetails: $brochureDetails, avatar:$avatar) {
      id
      brochureNumber
    }
  }
`;

export const EDIT_BROCHURE = gql`
  mutation EditBrochure($editBrochureId: String, $brochureDetails: brochureDetails, $avatar:Upload) {
    editBrochure(id: $editBrochureId, brochureDetails: $brochureDetails, avatar:$avatar) {
      id
      brochureNumber
    }
  }
`;

// ==============> COMPANY <================
export const CREATE_COMPANY = gql`
mutation Mutation($companyDetails: companyDetails) {
  addCompanies(companyDetails: $companyDetails) {
    id
    companyNumber
    name
  }
}
`;

export const EDIT_COMPANY = gql`
mutation Mutation($editCompanyId: ID, $companyDetails: companyDetails) {
  editCompanies(editCompanyId: $editCompanyId, companyDetails: $companyDetails) {
    SDIcode
    VAT
    id
  }
}`

// ==============> COMPANY CONTACT <================
export const EDIT_COMPANY_CONTACT = gql`
mutation Mutation($companyContactsDetails: companyContactsDetails, $editCompanyContactId: ID) {
  editCompanyContact(companyContactsDetails: $companyContactsDetails, id: $editCompanyContactId) 
}`
export const REGISTER_COMPANY_CONTACT = gql`
mutation RegisterCompanyAccount($companyContactsDetails: companyContactsDetails, $editCompanyContactId: ID) {
  registerCompanyAccount(companyContactsDetails: $companyContactsDetails, id: $editCompanyContactId) 
}`
export const CREATE_COMPANY_CONTACT = gql`
  mutation Mutation($companyContactsDetails: companyContactsDetails) {
    addCompanyContacts(companyContactsDetails: $companyContactsDetails) {
      id
      firstName
      taxCode
      lastName
      email
      gender
      phone
      role
    }
  }
`;

// ==============> INTERPRETATION PROJECT <================
export const PROPOSAL_NOTIFICATION_INTERPRETATION = gql`
mutation pageOpeningNotificationInterpretation($pageOpeningNotificationId: ID) {
  pageOpeningNotificationInterpretation(id: $pageOpeningNotificationId)
}
`
export const CREATE_CALENDAR_PROJECT = gql`
mutation AddCalendar($calendarDetails: calendarDetails) {
  addCalendar(calendarDetails: $calendarDetails) {
    id
  }
}
`;
export const EDIT_CALENDAR_PROJECT = gql`
mutation editCalendar(  $projectId:String , $calendarDetails: calendarDetails) {
  editCalendar(id: $projectId ,calendarDetails: $calendarDetails) {
    id
  }
}
`;

export const CREATE_INTERPRETATION_PROJECT = gql`
  mutation Mutation($interpretationOrderDetails: interpretationOrderDetails) {
    addInterpretationOrders(interpretationOrderDetails: $interpretationOrderDetails) {
      id
      orderNumber
    interpretationItem {
      startDateTime
      endDateTime
    }
    }
  }
`;

export const EDIT_INTERPRETATION_PROJECT = gql`
 mutation Mutation($editInterpretationOrdersId: String, $interpretationOrderDetails: interpretationOrderDetails) {
  editInterpretationOrders(id: $editInterpretationOrdersId, interpretationOrderDetails: $interpretationOrderDetails) {
    orderNumber
  }
}
`;
export const QUOTE_ACCEPT_INTERPRETATION = gql`
mutation QuoteAccept($quoteAcceptId: ID, $name: String, $optionSelected:Int) {
  quoteAccept(id: $quoteAcceptId, name: $name, optionSelected:$optionSelected)
}
`
export const PROJECT_INITIATE_INTERPRETATION = gql`
mutation InitiateProject($clientFiles:String, $initiateProjectId: ID ,$files: [Upload], $optionSelected:Int) {
  initiateProject(id: $initiateProjectId ,files: $files, optionSelected:$optionSelected, clientFiles:$clientFiles)
}
`

export const DUPLICATE_INTERPRETATION_QUOTE = gql`
mutation DuplicateQuoteOrder($duplicateQuoteOrderId: ID) {
  duplicateQuoteOrder(id: $duplicateQuoteOrderId) {
    accountManager {
      name
    }
    address1
    address2
    city
    companyNameAndId {
      id
      name
    }
    country {
      label
    }
    createdAtDate
    followUpDate
    grandTotal
    id
    orderNumber
  }
}
`
export const GENERATE_INTERPRETATION_PAYMENT_LINK = gql`
mutation Mutation($id:String, $amount:String){
  generatePaymentLink(id:$id, amount:$amount){
      url
      isPaid
      amount
  }
}
`
export const CREATE_INTERPRETATION_QUOTE = gql`
  mutation Mutation($interpretationOrderDetails: interpretationOrderDetails, $avatar:Upload) {
    addInterpretationQuoteOrders(interpretationOrderDetails: $interpretationOrderDetails, avatar:$avatar) {
      id
      orderNumber
    interpretationItem {
      startDateTime
      endDateTime
    }
    }
  }
`;

export const EDIT_INTERPRETATION_QUOTE = gql`
 mutation Mutation($editInterpretationOrdersId: String, $interpretationOrderDetails: interpretationOrderDetails, $avatar:Upload) {
  editInterpretationOrders(id: $editInterpretationOrdersId, interpretationOrderDetails: $interpretationOrderDetails, avatar:$avatar) {
    orderNumber
    followUpDate
    pmNotes
  }
}
`;
// ==============> VIDEO CONFERENCE PROJECT <================

export const CREATE_VIDEO_CONFERENCE_PROJECT = gql`
  mutation Mutation($videoConferenceOrderDetails: videoConferenceOrderDetails) {
    addVideoConferenceOrders(videoConferenceOrderDetails: $videoConferenceOrderDetails) {
      supportMaterialLink
    }
  }
`;
export const EDIT_VIDEO_CONFERENCE_PROJECT = gql`
  mutation EditVideoConferenceOrders(
    $editVideoConferenceOrdersId: String
    $videoConferenceOrderDetails: videoConferenceOrderDetails
  ) {
    editVideoConferenceOrders(
      id: $editVideoConferenceOrdersId
      videoConferenceOrderDetails: $videoConferenceOrderDetails
    ) {
      id
      orderNumber
    }
  }
`;

// ==============> LINGUIST <================

export const ADD_LINGUISTS = gql`
mutation Mutation($linguistAllocationsDetails: [linguistAllocationsDetails]) {
  addLinguistAllocations(linguistAllocationsDetails: $linguistAllocationsDetails ) {
    city
    clientId
    contactMethod
    country
    creationDate
    deliveryDateTime
    dob
    email
    endDateTime
    firstName
    gender
    iban
    sourceLanguage
    targetLanguage
    id
    lastName
    linguistId
    mainLanguage
    newWords
    notes
    orderNumber
    otherLanguage
    phone
    projectId
    projectManager
    projectType
    projectsCompleted
    ratePerAdditionalHour
    ratePerHour
    ratePerWord
    resume
    startDateTime
    status
    total
  }
}
`;
export const UPDATE_BLACKLIST = gql`
mutation UpdateBlacklist($updateBlacklistId: ID!, $isBlacklisted: Boolean! , $type:String!) {
  updateBlacklist(id: $updateBlacklistId, isBlacklisted: $isBlacklisted, type:$type) {
    isBlacklisted
  }
}
`;
export const UPDATE_GENERAL_BLACKLIST = gql`
mutation updateGeneralBlacklist($updateBlacklistId: ID!, $isBlacklisted: Boolean! ) {
  updateGeneralBlacklist(id: $updateBlacklistId, isBlacklisted: $isBlacklisted) {
    isBlacklisted
  }
}
`;
export const CREATE_TECHNOLOGY_MUTATION_INTER = gql`
  mutation updateTechnologyInterpretation(
   $landLines: Boolean
   $camera: Boolean
   $speedConnection: String 
   $hardware: [HardwareInput] 
   $machine: String
   $preferredBrowser: String
  ) {
    updateTechnologyInterpretation( 
      technologyInput:{
        landLines: $landLines
        camera: $camera
        speedConnection: $speedConnection
        hardware: $hardware
        machine: $machine
        preferredBrowser: $preferredBrowser
      }
    ) {
      landLines
    }
  }
`;


// ==============> EXPENSE ALLOCATION <================

export const ADD_EXPENSE_ALLOCATION = gql`
mutation Mutation($expenseAllocationsDetails: [expenseAllocationsDetails]) {
  addExpenseAllocations(expenseAllocationsDetails: $expenseAllocationsDetails)
}`
export const EDIT_EXPENSE_ALLOCATION = gql`
mutation EditExpenseAllocation($projectStatus:String, $editExpenseAllocationId: ID,$projectId:ID, $expenseAllocationsDetails: expenseAllocationsDetails) {
  editExpenseAllocation(projectStatus:$projectStatus, id: $editExpenseAllocationId, projectId : $projectId , expenseAllocationsDetails: $expenseAllocationsDetails)
}
`



// ==============> LINGUIST ALLOCATION <================
export const SEND_LINGUIST_ALLOCATION = gql`
mutation SendAllocationEmail($allocationEmail: allocationEmail) {
  sendAllocationEmail(allocationEmail: $allocationEmail)
}`;

export const SEND_PR_ALLOCATION = gql`
mutation SendPRAllocationEmail($allocationEmail: allocationEmail) {
  sendPRAllocationEmail(allocationEmail: $allocationEmail)
}`;
export const SEND_COMPLETE_ALLOCATION = gql`
mutation SendCompleteEmail($allocationEmail: allocationEmail) {
  sendCompleteEmail(allocationEmail: $allocationEmail)
}`;
export const SEND_COMPLETE_PR_ALLOCATION = gql`
mutation SendCompletePREmail($allocationEmail: allocationEmail) {
  sendCompletePREmail(allocationEmail: $allocationEmail)
}`;

export const INCREASE_FEEDBACK = gql`
mutation Mutation($linguistId: ID, $pmFeedback: Float) {
  increaseFeedbackScore(linguistId: $linguistId, pmFeedback: $pmFeedback)
}`;


export const SEND_CONFIRM_ALLOCATION = gql`
mutation SendConfirmEmail($allocationEmail: allocationEmail) {
  sendConfirmEmail(allocationEmail: $allocationEmail)
}`;
export const SEND_CONFIRM_PR_ALLOCATION = gql`
mutation SendPRConfirmEmail($allocationEmail: allocationEmail) {
  sendPRConfirmEmail(allocationEmail: $allocationEmail)
}`;

export const SEND_RESPONSE_ALLOCATION = gql`
mutation Mutation($allocationEmail: allocationEmail) {
  sendResponseEmail(allocationEmail: $allocationEmail)
}`
export const SEND_PR_RESPONSE = gql`
mutation SendPRResponseEmail($allocationEmail: allocationEmail) {
  sendPRResponseEmail(allocationEmail: $allocationEmail)
}`

export const ADD_REMAINING_LINGUIST_ALLOCATION = gql`
  mutation AddRemainingAllocations(
    $remainingAllocationsDetails: remainingAllocationsDetails
    $linguistAllocationId: String
  ) {
    addRemainingAllocations(
      remainingAllocationsDetails: $remainingAllocationsDetails
      linguistAllocationId: $linguistAllocationId
    ) {
      id
      firstName
      lastName
      email
      gender
      phone
      contactMethod
      dob
      mainLanguage
      otherLanguage
      country
      sourceLanguage
      targetLanguage
      iban
      resume
      notes
      total
      ratePerWord
      ratePerHour
      ratePerAdditionalHour
      newWords
      creationDate
      deliveryDateTime
      status
      projectId
      orderNumber
      projectType
      projectManager
      linguistId
      linguistDeliveryDate
      startDateTime
      endDateTime
    }
  }
`;


// LINGUIST PORTAL

export const EDIT_PROFILE_PIC_USER = gql`
mutation EditLinguistAvatar($file: Upload, $imageInput: imageInput) {
  editLinguistAvatar(file: $file, ImageInput: $imageInput){
    id
    firstName
    lastName
    email
    gender
    phone
    areaOfStudy
    contactMethod
    profileCompletion
    type
    dob
    city
    address
    otherLanguage
    mainLanguage
    country
    iban
    resume
    image
    avatarUrl
    name
    token
  }
}
`;


export const CREATE_ACTIVITY = gql`
mutation SaveTranslationActivty($translationActivity: TranslationActivity) {
  saveTranslationActivty(TranslationActivity: $translationActivity) {
    translation {
      isActive
      values {
        title
        sourceLanguage
        targetLanguage
        experience
      }
    }
  }
}
`;



export const CREATE_PAYMENT_MUTATION = gql`
  mutation updatePaymentInfo(
    $iban: String,
    $bic: String
    $bankname: String
    $taxid:String
    $profileCompletion: String!
    $paymentForm: Boolean!
  ) {
    updatePaymentInfo( 
      paymentInput:{
        iban: $iban
        bic: $bic
        bankname: $bankname
        taxid: $taxid
        profileCompletion: $profileCompletion
        paymentForm: $paymentForm
      }
    ) {
      iban   
    }
  }
`;



export const CREATE_WORK_MUTATION = gql`
  mutation updateWorkInfo(
    $profileTitle: String
    $resume: Upload
    $profileCompletion: String
    $workForm: Boolean
    $mainService:String
    $resumeURL: String
  ) {
    updateWorkInfo( 
      workInput:{
        profileTitle: $profileTitle
        resume: $resume
        profileCompletion: $profileCompletion
        workForm: $workForm
        mainService: $mainService
        resumeURL: $resumeURL
      }
    ) {
      profileTitle
      resume 
    }
  }
`;

export const CREATE_BUSINESS_MUTATION = gql`
  mutation updateBusinessInfo(
    $website: String,
    $workingFor: String
    $businessType: String
    $sizeClassification:String
    $numberEmployee: String
    $profileCompletion: String!
    $businessForm: Boolean!
  ) {
    updateBusinessInfo( 
      businessInput:{
        website: $website
        workingFor: $workingFor
        businessType: $businessType
        sizeClassification: $sizeClassification
        numberEmployee: $numberEmployee
        profileCompletion: $profileCompletion
        businessForm: $businessForm
      }
    ) {
      website   
    }
  }
`;


export const CREATE_PERSONAL_MUTATION = gql`
  mutation updatePersonelInfo(
    $firstName: String
    $lastName: String
    $gender: String
    $about:String
    $dob: String
    $nickname: String
    $phone: String
    $contactMethod: String
    $profileCompletion: String
    $personalForm: Boolean
  ) {
    updatePersonelInfo( 
      personelInput:{
      firstName: $firstName
      lastName: $lastName
      gender: $gender
      about: $about
      dob: $dob
      nickname: $nickname
      phone: $phone
      contactMethod: $contactMethod
      profileCompletion: $profileCompletion
      personalForm: $personalForm
      }
     
    ) {
         
          id
          firstName
          lastName
          email
          gender
          phone
          areaOfStudy
          contactMethod
          profileCompletion
          type
          dob
          city
          address
          otherLanguage
          mainLanguage
          country
          iban
          resume
          image
          avatarUrl
          name
          token
     
    }
  }
`;


export const CREATE_EDUCATION_MUTATION = gql`
  mutation updateEducationInfo(
    $college: String
    $startDate: String
    $endDate: String
    $degree:String
    $areaOfStudy: String
    $aboutQualification: String
    $profileCompletion: String!
    $educationForm: Boolean!
  ) {
    updateEducationInfo( 
      educationInput:{
        college: $college
        startDate: $startDate
        endDate: $endDate
        degree: $degree
        areaOfStudy: $areaOfStudy
        aboutQualification: $aboutQualification
        profileCompletion: $profileCompletion
        educationForm: $educationForm
      }
    ) {
      college
      startDate
      endDate
      degree
      areaOfStudy
      aboutQualification
     
    }
  }
`;

export const CREATE_ACTIVITY_MUTATION = gql`
  mutation updatePhoneInterpretation(
    $currency: String
    $RSI: [phoneInterpretationInput]
    $Onsite_Consecutive: [NotarizedInput]
    $RCI: [SwornInput]
    $Simultaneous: [backTranlationInput]
    $isRCIshow:Boolean
    $isRSIshow:Boolean
    $isOnsite_Consecutiveshow:Boolean
    $isSimultaneousshow:Boolean
  ) {
    updatePhoneInterpretation( 
      phoneInput:{
        currency: $currency
        RSI: $RSI
        Onsite_Consecutive: $Onsite_Consecutive
        RCI: $RCI
        Simultaneous: $Simultaneous
        isRCIshow:$isRCIshow
        isRSIshow:$isRSIshow
        isOnsite_Consecutiveshow:$isOnsite_Consecutiveshow
        isSimultaneousshow:$isSimultaneousshow
      } 
    ) {
      Onsite_Consecutive{
      	sourceLanguage
  	    targetLanguage
        experience
    }
    }
  }
`;
export const CREATE_UPDATE_TRANSLATION_EXPERTISE = gql`
mutation Mutation($translationExpertise: TranslationExpertise) {
  saveUpdateTranslationExpertise(TranslationExpertise: $translationExpertise) {
    selectedFields
    communication {
      corporateCommunication {
        isActive
        experience
        estimate
      }
      journalismPublications {
        isActive
        experience
        estimate
      }
      pressRelease {
        isActive
        experience
        estimate
      }
      publishing {
        isActive
        experience
        estimate
      }
    }
  }
}`;
export const CREATE_TRANSLATION_AVAILABILITY = gql`
mutation SaveTranslationAvailability($translationAvailability: TranslationAvailability) {
  saveTranslationAvailability(TranslationAvailability: $translationAvailability) {
    dailyTranslationOutput
    experienceOrProfessionalHistory
    fulltimeorpartTime
    mostTranslationRequests
    workingProfessionalTranslator {
      months
      years
    }
  }
}
`
export const CREATE_AVAILABILITY_MUTATION = gql`
  mutation updateAvailabilityInterpretation(
    $overPhoneAvailable: Boolean
   $freelancerType: String
   $newRequestTime: String
   $freelanceExperience: String
   $listClient: String
  ) {
    updateAvailabilityInterpretation( 
      availabilityInput:{
        overPhoneAvailable: $overPhoneAvailable
        freelancerType: $freelancerType
        newRequestTime: $newRequestTime
        freelanceExperience: $freelanceExperience
        listClient: $listClient
      }
    ) {
      freelancerType
    }
  }
`;



export const CREATE_RATE = gql`
mutation Mutation($translationRate: TranslationRate) {
  saveTranslationRate(TranslationRate: $translationRate) {
    Rate {
      activity
      languageSource
      languageTarget
      rateType
    }
  }
}
`;

export const CREATE_TECHNOLOGY_MUTATION = gql`
mutation SaveAndUpdateTranslationTechnology($translationTechnology: TranslationTechnology) {
  saveAndUpdateTranslationTechnology(TranslationTechnology: $translationTechnology) {
    wordfast {
      wordfastPro3
      wordfastPro5
      wordfastClassic
      wordfastAnywhere
    }
    alchemyCetalyst {
      cetalyst7
      cetalyst8
      cetalyst9
      cetalyst12
    }
    alchemyPublisher {
      publisher2
      publisher3
    }
    trados {
      studio2015
      studio2017
      studio2019
      studio2021
    }
    otherComputerAssistedTranslationTools {
      transcend
      sdlx
      dejaVu
      passolo
      idiom
      msLocStudio
      memoQ
      omegaT
      across
    }
    software {
      adobePdf
      catCourtReporting
      eclipseCourtReporting
      visio
      msOffice
      tmsEtma
    }
  }
}
`;


export const CREATE_CONTACT_MUTATION = gql`
  mutation updateContactInfo(
    $mainLanguage: String!
    $otherLanguage: String!
    $liveOtherCountry: String!
    $yearOtherCountry:String!
    $address1: String!
    $city: String!
    $address2: String!
    $country: String!
    $livesin: String!
    $postal: String! 
    $timezone: String!
    $profileCompletion: String!
    $contactForm: Boolean!

  ) {
    updateContactInfo( 
      contactInput:{
        mainLanguage: $mainLanguage
        otherLanguage: $otherLanguage
        liveOtherCountry: $liveOtherCountry
        yearOtherCountry: $yearOtherCountry
        address1: $address1
        address2: $address2
        city: $city
        country: $country
        livesin: $livesin
        postal: $postal
        timezone: $timezone
        profileCompletion: $profileCompletion
        contactForm: $contactForm
      }
     
    ) {
      mainLanguage
      otherLanguage
      liveOtherCountry
      yearOtherCountry
      address1
      address2
      city
      country
      livesin
      postal
      timezone
     
    }
  }
`;

export const CREATE_RATE_MUTATION = gql`
  mutation updateRateInterpretation(
    $currency: String
    $travelExpense: String
    $cancelationTerm: String
  ) {
    updateRateInterpretation( 
      rateInput:{
        currency: $currency
        travelExpense: $travelExpense
        cancelationTerm: $cancelationTerm
      } 
    ) {
        currency
  	    travelExpense
        cancelationTerm
    }
  }
`;




export const CREATE_UPDATE_EXPERTISE = gql`
  mutation Mutation($interpreterExpertise: InterpreterExpertise) {
    updateExpertiseInterpretation(InterpreterExpertise: $interpreterExpertise) {
      asylum {
        isChecked
        assignmentTime
      }
      attorney {
        isChecked
        assignmentTime
      }
      deposition {
        isChecked
        assignmentTime
      }
      fcpa {
        isChecked
        assignmentTime
      }
      insurance {
        isChecked
        assignmentTime
      }
      intellectual {
        isChecked
        assignmentTime
      }
      redundancy {
        isChecked
        assignmentTime
      }
      trial {
        isChecked
        assignmentTime
      }
      workerCompensation {
        isChecked
        assignmentTime
      }
      healthcareOffice {
        isChecked
        assignmentTime
      }
      hospitalExam {
        isChecked
        assignmentTime
      }
      medicalDevice {
        isChecked
        assignmentTime
      }
      medicalExam {
        isChecked
        assignmentTime
      }
      mentalHealth {
        isChecked
        assignmentTime
      }
      audit {
        isChecked
        assignmentTime
      }
      investigatorsMeeting {
        isChecked
        assignmentTime
      }
      computerHardware {
        isChecked
        assignmentTime
      }
      energy {
        isChecked
        assignmentTime
      }
      Patents {
        isChecked
        assignmentTime
      }
      engineering {
        isChecked
        assignmentTime
      }
      advisoryBoards {
        isChecked
        assignmentTime
      }
      agribusiness {
        isChecked
        assignmentTime
      }
      businessMeet {
        isChecked
        assignmentTime
      }
      finance {
        isChecked
        assignmentTime
      }
      humanResources {
        isChecked
        assignmentTime
      }
      shareholderMeet {
        isChecked
        assignmentTime
      }
      aviation {
        isChecked
        assignmentTime
      }
      automotive {
        isChecked
        assignmentTime
      }
      railroads {
        isChecked
        assignmentTime
      }
      maritime {
        isChecked
        assignmentTime
      }
      disciplinaryMeet {
        isChecked
        assignmentTime
      }
      iepMeet {
        isChecked
        assignmentTime
      }
      parentMeet {
        isChecked
        assignmentTime
      }
      specialEducation {
        isChecked
        assignmentTime
      }
      studentEvaluations {
        isChecked
        assignmentTime
      }
      diplomacy {
        isChecked
        assignmentTime
      }
      disasterRecovery {
        isChecked
        assignmentTime
      }
      euParliament {
        isChecked
        assignmentTime
      }
      internationalDevelopment {
        isChecked
        assignmentTime
      }
      publicHealth {
        isChecked
        assignmentTime
      }
      socialServices {
        isChecked
        assignmentTime
      }
      stateDepartment {
        isChecked
        assignmentTime
      }
      unitedNations {
        isChecked
        assignmentTime
      }
      culturalConsultant {
        isChecked
        assignmentTime
      }
      cultureArts {
        isChecked
        assignmentTime
      }
      mediaPress {
        isChecked
        assignmentTime
      }
      personalAssistant {
        isChecked
        assignmentTime
      }
      retail {
        isChecked
        assignmentTime
      }
      sports {
        isChecked
        assignmentTime
      }
      tourGuide {
        isChecked
        assignmentTime
      }
      foodBeverage {
        isChecked
        assignmentTime
      }
      marketing {
        isChecked
        assignmentTime
      }
    }
  }`;

export const CREATE_NEW_PASSWORD_LINGUIST = gql`
mutation Mutation($email: String, $password: String, $authCode: String) {
  newPassword(email: $email, password: $password, authCode: $authCode)
}`;
export const CREATE_NEW_PASSWORD_CLIENT = gql`
mutation Mutation($email: String, $password: String, $authCode: String) {
  newPasswordClient(email: $email, password: $password, authCode: $authCode)
}`;
export const CREATE_CALL_RECORD = gql`
 mutation SaveCallRecord(
  $callerNum: String!
  $duration: Int!
  $callStatus: String!
  $DateandTime: String!
  $startTime: String
  $endTime: String
  
) {
  saveCallRecord(
    callerNum: $callerNum
    duration: $duration
    callStatus: $callStatus
    DateandTime: $DateandTime
    startTime: $startTime
    endTime: $endTime
  ) {
    callerNum
    employeeID
    DateandTime
    _id
    callStatus
    duration
    endTime
    startTime
  }
}
`;


// ==============> UPDATE USER DETAILS <================

export const UPDATE_USER_DETAILS = gql`
mutation EditUser($userDetails: userEditDetails, $file: Upload) {
  editUser(userDetails: $userDetails, file: $file){
    id
      email
      role
      name
      type
      phoneNumber
      avatarUrl
      department
      description
      jobTitle
      token
      language{
        code
        name
        nativename
      }
      personalEmail
      address1
      address2
      city
      postcode
      dob
      country{
        code
        label
        phone
      }
  }
}
`;

// ==============> UPLOAD FILES <================

export const UPLOAD_FILES = gql`
mutation uploadFile(
  $files: [Upload]
  $projectType: String
  $orderNumber: String
  $projectId: String
  $fileType: String
  $userId: String
  $userType: String
) {
  uploadFile(
    files: $files
    projectType: $projectType
    orderNumber: $orderNumber
    projectId: $projectId
    fileType: $fileType
    userId: $userId
    userType: $userType
  ) {
    id
    orderNumber
    projectId
    projectType
    createdAt
    folders {
      original {
  fileName
  size
}
translated {
  fileName
  size
}
        Support_Material{
          fileName
          size
        }
        project_manager{
          fileName
          size
        }
        client_delivery{
          fileName
          size
        }
    }
  }
}
`;

export const EDIT_FILES = gql`
mutation editFiles(
  $file: Upload
  $id: String
  $fileType: String
  $projectId: String
  $projectType: String
  $orderNumber: String
  $linguistId: String
  $userId: String
  $userType: String
  ) {
  editFiles(
    file: $file
    id: $id
    fileType: $fileType
    projectId: $projectId
    projectType: $projectType
    orderNumber: $orderNumber
    linguistId:$linguistId
    userId: $userId
    userType: $userType
  ) 
}
`;
export const CREATE_FOLDER = gql`
mutation createFolder(
  $id: String
  $fileType: String
  ) {
  createFolder(
    id: $id
    fileType: $fileType
  ) 
}
`;
export const DELETE_FILES = gql`
mutation DeleteFiles($fileName: String, $id: String , $type: String) {
  deleteFiles(fileName: $fileName, id: $id , type: $type) 
}
`;
export const SEND_PM_TRANSLATION_SUBMISSION = gql`
mutation SendPMReviewEmail($pmReviewEmail: pmReviewEmail) {
  sendPMReviewEmail(pmReviewEmail: $pmReviewEmail)
}
`
export const SEND_PM_TRANSLATION_PR_SUBMISSION = gql`
mutation SendPRReviewEmail($pmReviewEmail: pmReviewEmail) {
  sendPRReviewEmail(pmReviewEmail: $pmReviewEmail)
}`
export const SEND_LINGUIST_REVISION_REQUEST = gql`
mutation SendReviewRequestEmail($reviewRequestEmail: reviewRequestEmail) {
  sendReviewRequestEmail(reviewRequestEmail: $reviewRequestEmail)
}
`
export const ADD_POST = gql`
mutation AddPost($postDetails: postDetails) {
  addPost(postDetails: $postDetails) 
}
`;
export const CREATE_LOGS = gql`
mutation createLog($logDetails: logDetails) {
  createLogs(logDetails: $logDetails) {
    action
    DateAndTime
    _id
    uid
    user {
      id
      name
    }
  }
}`
export const CREATE_ISSUE = gql`
mutation AddIssue($issueDetails: issueDetails){
  addIssue(issueDetails: $issueDetails){
    id
    issueNumber
    orderNumber
    projectType
    responsibleEmployee
  }
}`
export const EDIT_ISSUE = gql`
mutation EditIssue($editIssueId: ID!, $issueDetails: issueDetails){
  editIssue(id: $editIssueId, issueDetails: $issueDetails)
}`

export const SEND_CREATE_ISSUE_EMAIL = gql`mutation SendIssueCreationEmail(
  $issueNumber: Int
  $orderNumber: String
  $projectType: String
  $responsibleEmployee: [String]

) {
  sendIssueCreationEmail(
    issueNumber: $issueNumber
    orderNumber: $orderNumber
    projectType: $projectType
    responsibleEmployee: $responsibleEmployee

  )
}`
export const SEND_ISSUE_UPDATE_EMAIL = gql`
mutation sendIssueUpdateEmail(
  $issueNumber: Int
  $orderNumber: String
  $projectType: String
) {
  sendIssueUpdateEmail(
    issueNumber: $issueNumber
    orderNumber: $orderNumber
    projectType: $projectType
  )
}`
export const UPDATE_FEEDBACK = gql`
mutation UpdateFeedbackScore($linguistId: ID, $allocationId: String, $pmFeedback: Float) {
  updateFeedbackScore(linguistId: $linguistId, allocationID: $allocationId, pmFeedback: $pmFeedback)
}`
export const SEND_TECHNICIAN_EMAIL = gql`
mutation SendEmailToTechnician(
  $email: String
  $projectNo: String
  $address: String
  $fullName: String
  $url: String
) {
  sendEmailToTechnician(
    email: $email
    projectNo: $projectNo
    address: $address
    fullName: $fullName
    url: $url
  )
}`
export const SEND_FORMATTING_EMAIL = gql`
mutation SendEmailToFormatter(
  $email: String
  $projectNo: String
  $fullName: String
  $url: String
  $projectManager:String
  $endDateTime:String
  $totalWords:String
  $type:String
) {
  sendEmailToFormatter(
    email: $email
    projectNo: $projectNo
    fullName: $fullName
    url: $url
    projectManager:$projectManager
    endDateTime:$endDateTime
    totalWords:$totalWords
    type:$type
  )
}`
export const SEND_TECHNICIAN_RESPONSE_EMAIL = gql`
mutation SendEmailTechnicianResponse(
  $fullName: String
  $projectNo: String
  $url: String
  $status: String
) {
  sendEmailTechnicianResponse(
    fullName: $fullName
    projectNo: $projectNo
    url: $url
    status: $status
  )
}
`
export const SEND_TECHNICIAN_REFUSED_EMAIL= gql`
mutation SendEmailTechnicianRefused(
  $email: String
  $orderNumber: String
  $technicianName: String
  $projectManager: String
) {
  sendEmailTechnicianRefused(
    email: $email
    orderNumber: $orderNumber
    technicianName: $technicianName
    projectManager: $projectManager
  )
}
`
export const UPDATE_LINGUIST_PASSWORD = gql`
mutation UpdateLinguistPassword($oldPassword: String!, $newPassword: String!) {
  updateLinguistPassword(oldPassword: $oldPassword, newPassword: $newPassword)
}`
export const ADD_PR_ALLOCATION = gql`
mutation AddPRAllocations($PRAllocationsDetails: [PRAllocationsDetails]) {
  addPRAllocations(PRAllocationsDetails: $PRAllocationsDetails) {
    id
    firstName
    lastName
    email
    city
    mainLanguage
    otherLanguage
    country
    orderNumber
    sourceLanguage
    targetLanguage
    notes
    total
    ratePerWord
    ratePerHour
    ratePerAdditionalHour
    newWords
    creationDate
    startDateTime
    endDateTime
    deliveryDateTime
    status
    linguistId
    clientId
    projectsCompleted
    projectManager
    projectId
    projectType
    negotiatedRatePerWord
    negotiatedTotal
    negotiatedRatePerHour
    negotiatedDeliveryDateTime
    reviewReasons
    negotiatedRatePerAdditionalHour
    prDeliveryDate
  }
}`
export const UPDATE_PR_ALLOCATION = gql`
mutation UpdatePRAllocations($prAllocationId: String, $prAllocationUpdateDetail: PRAllocationUpdateDetail) {
  UpdatePRAllocations(PRAllocationId: $prAllocationId, PRAllocationUpdateDetail: $prAllocationUpdateDetail) {
    id
    firstName
    lastName
    email
    city
    mainLanguage
    otherLanguage
    country
    orderNumber
    sourceLanguage
    targetLanguage
    notes
    total
    ratePerWord
    ratePerHour
    ratePerAdditionalHour
    newWords
    creationDate
    startDateTime
    endDateTime
    deliveryDateTime
    status
    linguistId
    clientId
    projectsCompleted
    projectManager
    projectId
    projectType
    negotiatedRatePerWord
    negotiatedTotal
    negotiatedRatePerHour
    negotiatedDeliveryDateTime
    reviewReasons
    negotiatedRatePerAdditionalHour
    prDeliveryDate
    linguistallocations
  }
}`
export const CLIENT_LOGIN = gql`
mutation ClientLogin($email: String!, $password: String!, $recaptcha:String) {
  login(email: $email, password: $password, recaptcha:$recaptcha) {
    id
    lang
    name
    token
    companyName
    address1
    address2
    country
    city
    zipcode
    phone
    industry
    companyVat
    email
    type
    isVerified
  }
}`
export const CLIENT_REGISTER = gql`
mutation ClientRegister($registerInput: RegisterInput) {
  register(registerInput: $registerInput) {
    id
    name
    token
    createdAt
    companyName
    address1
    address2
    country
    city
    zipcode
    phone
    industry
    companyVat
    email
    type
  }
}`

export const FORGOT_USER = gql`
mutation ForgotLink($email: String!) {
  forgotLink(email: $email) {
    email
  }
}
`;
export const RESET_CLIENT_PASSWORD = gql`
  mutation resetPassword(
    $email: String
    $password: String
    $confirmPassword: String
    $forgotCode: String
  ) {
    resetPassword(
      resetInput: {
        email: $email
        password: $password
        confirmPassword: $confirmPassword
        forgotCode: $forgotCode
      }
    ) {
      email
    }
  }
`;


export const UPDATE_CLIENT_PERSONAL_INFO = gql`
mutation UpdateClientInfo($clientpersonelInput: ClientPersonelInput) {
  updateClientInfo(clientpersonelInput: $clientpersonelInput) {
    about
  }
}
`;
export const UPDATE_CLIENT_ADDRESS_INFO = gql`
mutation UpdateClientContactInfo($contactDetails: ClientContactInput) {
  updateClientContactInfo(contactDetails: $contactDetails) {
    address2
  }
}
`
export const UPDATE_CLIENT_BUSINESS_INFO = gql`
mutation UpdateClientBusinessInfo($businessInput: ClientBusinessInput) {
  updateClientBusinessInfo(businessInput: $businessInput) {
    consolidatedInvoice
  }
}`
export const CREATE_BOOKINTERPRETER_MUTATION = gql`
mutation InsertBookInterpreter($bookInterpreterInput: BookInterpreterInput) {
  insertBookInterpreter(bookInterpreterInput: $bookInterpreterInput) {
      id
      yourLanguage
  }
}
`;
export const UPDATE_INTERPRETER_FORM_TWO= gql`
mutation UpdateSecondFormInterpreter(
  $formTwoInterpreterInput: FormTwoInterpreterInput
) {
  updateSecondFormInterpreter(
    formTwoInterpreterInput: $formTwoInterpreterInput
  ) {
    activeStatus
  }
}

`
export const UPDATE_INTERPRETER_FORM_THREE = gql`
mutation UpdateThirdFormInterpreter($formThirdInterpreterInput: FormThirdInterpreterInput) {
  updateThirdFormInterpreter(formThirdInterpreterInput: $formThirdInterpreterInput) {
    price
  }
}`

export const CREATE_BOOKINTERPRETER_SECOND_MUTATION = gql`
mutation updateInterpreterConfirmation(
  $activeStatus: Boolean!
  $id:ID
  $isPaid: Boolean!
  $isConfirmed: Boolean!
  $status:String
) {
  updateInterpreterConfirmation(
    confirmationInterpreterInput: {
      activeStatus: $activeStatus
      isPaid: $isPaid
      id:$id
      isConfirmed: $isConfirmed
      status:$status
    }
  ) {
    activeStatus
  }
}`
export const CREATE_BOOKTRANSLATOR_SECOND_MUTATION = gql`
mutation updateTranslationConfirmation(
  $activeStatus: Boolean!
  $id:ID
  $isPaid: Boolean!
  $isConfirmed: Boolean!
  $status:String
) {
  updateTranslationConfirmation(
    confirmationTranslationInput: {
      activeStatus: $activeStatus
      isPaid: $isPaid
      id:$id
      isConfirmed: $isConfirmed
      status:$status
    }
  ) {
    activeStatus
  }
}`
export const CREATE_NEW_WEB_CONFERENCE = gql`
mutation InsertWebconferencing($webconferencingInput: WebconferencingInput) {
  insertWebconferencing(webconferencingInput: $webconferencingInput) {
    id
    numberHour
    numberParticipant
    numberLanguages
    totalAmount
    progress
    stepOneForm
  }
}`
export const UPDATE_WEB_CONFERENCE_FORM_TWO = gql`
mutation UpdateWebRquirement($webRequirementInput: WebRequirementInput) {
  updateWebRquirement(webRequirementInput: $webRequirementInput) {
    
    id
    serviceDate
    additionalServices{
      description
      price
      quantity
      serviceTotal
      discount
      recordVideoAndAudio
      videoEditing
      postProduction
      logoIntegration
      pollingSystem
    }
 
    requireInterpreter
    message
    progress
    stepTwoForm
    marketingPackage {
      completeBranding
      socialMedia
      platformLinked
      personalizedEmailInvitation
      personalizedEmailEvent
    
    }
  }
}`

export const UPDATE_BOOK_WEB_CONFERENCE = gql`
mutation updateWebConfirencingConfirmation(
  $id: ID
  $activeStatus: Boolean!
  $isPaid: Boolean!
  $isConfirmed: Boolean!
  $status:String
) {
  updateWebConfirencingConfirmation(
    confirmationInterpreterInput: {
      id: $id
      activeStatus: $activeStatus
      isPaid: $isPaid
      isConfirmed: $isConfirmed
      status:$status
    }
  ) {
    id
    isPaid
    isConfirmed
    activeStatus
  }
}
`;
export const CREATE_INVOICE = gql`
mutation CreateInvoice($invoiceInput: InvoiceInput) {
  createInvoice(InvoiceInput: $invoiceInput) {
    invoiceStatus
    invoiceTotal
    _id
    items {
      title
      description
      total
    }
  }
}
`
export const EDIT_INVOICE= gql`
mutation EditInvoice($editInvoiceId: ID!, $invoiceStatus: String!) {
  editInvoice(id: $editInvoiceId, invoiceStatus: $invoiceStatus) {
    _id
    createdAt
    invoiceDueDate
    invoiceNotes
    invoiceFrom {
      name
      address1
        address2
        city
        postalCode
        country
      phone
      bankName
      IBAN
      BIC
      TAXID
    }
    invoiceID
    invoiceStatus
    invoiceTo {
      name
      address1
        address2
        city
        postalCode
        country
      phone
    }
    
    invoiceTotal
    items {
      title
      description
      total
    }
    month
    
    vendorID
    vendorName
  }
}`
export const TRANSLATION_CLIENT_FORM_MUTATION = gql`
  mutation insertTranslationProject(
    $translationProjectInput:TranslationProjectInput
  ) {
    insertTranslationProject(translationProjectInput:$translationProjectInput)
  }
`;
export const UPDATE_CLIENT_INVOICE= gql`
mutation UpdateInvoice($updateInvoiceId: ID!, $invoiceInput: InvoiceInput) {
  updateInvoice(id: $updateInvoiceId, InvoiceInput: $invoiceInput) {
    vendorName
  }
}`
export const DELETE_INVOICE= gql`
mutation DeleteInvoice($invoiceId: ID!) {
  deleteInvoice(id: $invoiceId)
}`
export const EDIT_TASK_TO_ARCHICE = gql`
mutation EditTaskToArchive($editTaskToArchiveId: ID) {
  editTaskToArchive(id: $editTaskToArchiveId) {
    taskNumber
    summary
    status
    priority
    name
    level
    id
    dueDate
    createdAtDate
    assignorName {
      name
      email
    }
    assigneeName {
      email
      name
    }
  }
}`
export const EDIT_TASKS_LIST_TO_ARCHIVE = gql`
mutation EditTaskListToArchive($ids: [ID]) {
  editTaskListToArchive(ids: $ids) {
    taskNumber
    summary
    priority
    status
    name
    level
    id
    dueDate
    createdAtDate
    assignorName {
      name
      email
    }
    assigneeName {
      name
      email
    }
  }
}`
export const MARK_BARZANO_PROJECT_BILLED = gql`
mutation MarkBarzanoProjectsBilled($ids: [String],$invoiceID:String!) {
  markBarzanoProjectsBilled(ids: $ids, invoiceID:$invoiceID)
}`
export const UPDATE_ORDERS_BILLING_STATUS = gql`
mutation UpdateProjectsBillingStatus($ids: [String],$invoiceID:String!) {
  updateProjectsBillingStatus(ids: $ids , invoiceID:$invoiceID)
}
`
export const CREATE_TRANSLATION_QUOTE_PAYMENT = gql`
  mutation updateTranslationPayment($grandTotal:String $tokenStripe: String , $billingInfo:JSON) {
    updateTranslationPayment(
      confirmationPayTranslationInput: {grandTotal:$grandTotal,  tokenStripe: $tokenStripe, billingInfo:$billingInfo  }
    ) {
      tokenStripe
    }
  }`;
  export const CREATE_INTERPRETATION_QUOTE_PAYMENT = gql`
  mutation updateInterpretationPayment($grandTotal:String $tokenStripe: String , $billingInfo:JSON) {
    updateInterpretationPayment(
      confirmationPayInterpretInput: {grandTotal:$grandTotal,  tokenStripe: $tokenStripe, billingInfo:$billingInfo  }
    ) {
      tokenStripe
    }
  }`;
  export const CREATE_WEB_CONFERENCE_QUOTE_PAYMENT = gql`
  mutation updateWebPayment($grandTotal:String $tokenStripe: String , $billingInfo:JSON) {
    updateWebPayment(
      confirmationPayWebInput: {grandTotal:$grandTotal,  tokenStripe: $tokenStripe, billingInfo:$billingInfo  }
    ) {
      tokenStripe
    }
  }`;
  export const RENAME_VAULT_FILE = gql`
  mutation RenameFile(
    $fileName: String!
    $renameFileId: String
    $type: String
    $newFileName: String!
  ) {
    renameFile(
      fileName: $fileName
      id: $renameFileId
      type: $type
      newFileName: $newFileName
    )
  }`

  export const CREATE_SUPPLIER = gql`
  mutation AddSupplier( $inputSupplier: InputSupplier!) {
  addSupplier( inputSupplier: $inputSupplier)
}`
export const ADD_EXPENSE = gql`
mutation AddExpense($inputExpense: InputExpense!) {
  addExpense(inputExpense: $inputExpense)
}`
export const EDIT_EXPENSE = gql`
mutation EditExpense($inputExpense: InputExpense!  $expenseId: String) {
  editExpense(inputExpense: $inputExpense,expenseID: $expenseId)
}`
export const GENERATE_EXPENSE_ON_FATTURE = gql`
mutation SentExpenseToFatturein($id: String!) {
  sentExpenseToFatturein(id: $id)
}`
export const ADD_TM_REPORT = gql`
mutation AddTMReport($tmReportDetails: TMReportDetails) {
  addTMReport(tmReportDetails: $tmReportDetails)
}`
export const EDIT_TM_REPORT = gql`
mutation EditTMReport($tmReportDetails: TMReportDetails, $id: String) {
  editTMRerport(tmReportDetails: $tmReportDetails, id: $id)
}`
export const DELETE_REPORT = gql`
mutation DeleteReport($id: String!) {
  deleteReport(id: $id)
}`
export const ADD_INVENTORY_ITEM = gql`
mutation AddInventory($inventoryInput: InventoryInput!) {
  addInventory(inventoryInput: $inventoryInput) {
    name
  }
}`

export const EDIT_INVENTORY_ITEM = gql`
mutation UpdateInventory($inventoryUpdateInput: InventoryUpdateInput!) {
  updateInventory(inventoryUpdateInput: $inventoryUpdateInput) {
    id

  }
}`
export const DELETE_INVENTORY = gql`
mutation DeleteInventory($id: ID!) {
  deleteInventory(id: $id)
}`

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
// @mui
import { Container, Skeleton } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// GQL
import { BROCHURE_DETAILS, GET_TM_REPORT } from '../../graphQL/queries';
// Components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import TMReportEditForm from '../../sections/@dashboard/TMReport/TMReportEditForm';

// ----------------------------------------------------------------------

export default function TMReportEdit() {
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_TM_REPORT,
    {
      variables: { id: id },
      fetchPolicy: 'no-cache',
    });

  if (error) return <div>Error: {error.message} </div>
  return (
    <Page title="Translation Memory : Edit a Translation Memory ">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={'Edit Translation Memory '}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Translation Memory List',
              href: PATH_DASHBOARD.TMReport.list,
            },
            { name: 'Translation Memory ' },
          ]}
        />
        {
          (!data && loading) &&
          <Skeleton variant="rectangular" width="100%" height={600} />}{
          (data && !loading) &&
          <TMReportEditForm currentTMReport={data.TMReport} isEdit={true} />
        }
      </Container>
    </Page>
  );
}

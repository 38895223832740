
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
// @mui
import { Button, Grid, Stack, Typography, } from '@mui/material';
// GQL
import { DELETE_REPORT, } from 'src/graphQL/mutations';
import { LoadingButton } from '@mui/lab';
// import LoadingButton from 'src/theme/overrides/LoadingButton';

// ----------------------------------------------------------------------

export default function DeleteConfirmation({ handleCloseModal, id, project, refetch }) {
    const { enqueueSnackbar } = useSnackbar();
    const [deleteReport, { loading }] = useMutation(DELETE_REPORT);

    const handleDeleteFile = async () => {
        enqueueSnackbar(
            `Deleting Report`, { variant: 'info', }
        );
        try {
            await deleteReport({ variables: { id: id }, }).then((res) => {
                refetch();
                enqueueSnackbar('File deleted successfully!');
                handleCloseModal();

            })
        } catch (error) {
            enqueueSnackbar('Error!', { variant: "error" });

        }

    };

    return (
        <Grid container sx={{ p: 4 }} spacing={4} justifyContent="center">
            <Grid item xs={12} >
                <Typography variant="subtitle1">Are you sure you want to delete the file Report For project {project?.type}/{project.orderNumber}</Typography>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} mt={4} style={{
                    display: "flex", justifyContent: "flex-end"
                }}>
                    <LoadingButton variant="contained" loading={loading} color="error" onClick={handleDeleteFile}>
                        Delete
                    </LoadingButton>
                    <Button variant="contained" color="info" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
}
